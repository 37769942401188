import { useApi } from '@tenant/composables'
import { useQuery } from '@tanstack/vue-query'

export const useTenantAccountant = () => {
    const { execute } = useApi('/api/tenant-accountants', 'GET')
    const accountants = computed(() => data.value ?? [])

    const { data, isLoading } = useQuery({
        queryKey: ['tenant-accountants'],
        queryFn: () => execute(),
        staleTime: Infinity,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
    })

    return { accountants, execute, isLoading }
}
